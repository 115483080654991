import React from 'react'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import ContactForm from '../components/ContactForm'

function ContactPage() {
    return (
        <>
            <HelmetProvider>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>GroupInnov - Nous contacter</title>
                    <meta name="description" content="GroupInnov, votre contractant général de confiance à Trets, assure la gestion complète de votre projet de construction. De la planification initiale à la remise des clés, nous vous offrons une solution clé en main, garantissant qualité, respect des délais et maîtrise budgétaire. Faites confiance à notre expertise pour concrétiser vos visions architecturales." />
                    <meta name="keywords" content="contractant général, construction, gestion de projet, solution clé en main, GroupInnov, Trets, qualité, délais, budget" />
                </Helmet>
            </HelmetProvider>
            <main>
                <section>
                    <ContactForm />
                </section>
            </main>


        </>
    )
}

export default ContactPage