import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react";

import ScrollToTop from "./components/ScrollToTop";

import CookieConsent from "./components/CookieConsent";
import { useCookies } from "react-cookie";
import ContactPage from "./pages/ContactPage";

function App() {
  const [cookies] = useCookies(["cookieConsent"]);





  return (
    <BrowserRouter>
      <ScrollToTop />
      {!cookies.cookieConsent && <CookieConsent />}



      <Routes>
        <Route path="/" exact element={<ContactPage />} />

        <Route path='*' element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
