import { Box, Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';

const CookieConsent = () => {
    const [cookies, setCookie] = useCookies(["cookieConsent"]);
    const [showConsent, setShowConsent] = useState(!cookies.cookieConsent);

    const giveCookieConsent = () => {
        setCookie("cookieConsent", true, { path: "/" });
        setShowConsent(false);  // Cacher le message
    };

    const declineCookieConsent = () => {
        setCookie("cookieConsent", false, { path: "/" });
        setShowConsent(false);  // Cacher le message
    };

    if (!showConsent) return null;

    return (
        <Box sx={{ textAlign: 'center', position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0, 0, 0, 0.8)', zIndex: 1000, padding: 2 }}>
            <Typography paragraph sx={{ color: 'white', fontFamily: 'Lato, sans-serif' }}>
                Nous utilisons des cookies pour améliorer votre expérience utilisateur. En utilisant notre site web,
                vous acceptez notre utilisation des cookies.{" "}
                <Typography component={'a'} sx={{ color: 'white', cursor: 'pointer' }} href={"/mentions-legales"}>En savoir plus.</Typography>
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
                <Button variant="contained" color="primary" onClick={giveCookieConsent}>
                    Accepter
                </Button>
                <Button variant="contained" color="error" onClick={declineCookieConsent}>
                    Refuser
                </Button>
            </Box>
        </Box>
    );
};

export default CookieConsent;
