// Ajouter bannière + texte TODO
// Finir style icon formulaire TODO

import React, { useState } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import FactoryIcon from '@mui/icons-material/Factory';
import DescriptionIcon from '@mui/icons-material/Description';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HomeIcon from '@mui/icons-material/Home';

import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import HardwareIcon from '@mui/icons-material/Hardware';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ExtensionIcon from '@mui/icons-material/Extension';

//FactoryIcon
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BusinessIcon from '@mui/icons-material/Business';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ScienceIcon from '@mui/icons-material/Science';
import MovieIcon from '@mui/icons-material/Movie';
import StoreIcon from '@mui/icons-material/Store';

import Crop169Icon from '@mui/icons-material/Crop169';
import Crop32Icon from '@mui/icons-material/Crop32';
import CropDinIcon from '@mui/icons-material/CropDin';
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import { useForm, ValidationError } from '@formspree/react';
function ContactForm() {
    const [step, setStep] = useState(1);

    const [formData, setFormData] = useState({
        userType: "",
        projectType: "",
        buildingType: "",
        buildingSize: "",
        nom: "",
        prenom: "",
        email: "",
        telephone: "",
        villeDuProjet: "",
        societe: "",
        description: ""
    });

    const [state, handleSubmit] = useForm("xgejwjaz");
    if (state.succeeded) {
        return (
            <>
                <Box sx={{ marginTop: '280px', marginBottom: '280px', textAlign: 'center' }}>
                    <Typography variant='h3' color='green' sx={{ fontFamily: 'Lato, sans-serif', textAlign: 'center' }}>Merci pour votre message !</Typography>
                    <Typography variant='h3' color='green' sx={{ fontFamily: 'Lato, sans-serif', textAlign: 'center' }}>Nous vous répondrons dans les plus brefs délais.</Typography>
                </Box>
            </>
        )
    }



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSelection = (field, value) => {
        setFormData(prev => ({ ...prev, [field]: value }));
        setStep(prev => prev + 1);
    };

    const handleBack = (field) => {
        setFormData(prev => ({ ...prev, [field]: "" }));
        setStep(prev => prev - 1);
    }

    return (
        <>
            <Box sx={{ marginBottom: '200px' }}>
                <Box sx={{ width: '100%', marginBottom: '50px', display: 'flex', alignItems: 'center', height: '350px', }}>
                    <Typography variant='h2' color='white' sx={{ fontFamily: 'Lato, sans-serif', backgroundColor: 'rgba(0,0,0,0.25)', padding: '20px', textAlign: 'center', width: '100%' }}>Site web en construction, n'hésitez pas à nous contacter via le formulaire ci dessous.</Typography>
                </Box>
                {step === 1 && (
                    <>
                        <Box sx={{ backgroundColor: 'rgba(62,139,190,0.6)', padding: '20px', width: '100%', display: 'flex', flexDirection: 'row', margin: 'auto', marginTop: '70px', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', gap: '30px' }}>
                            <Typography variant='h4' sx={{ padding: '20px', color: 'white', textAlign: 'center', width: '100%', fontFamily: 'Lato, sans-serif' }}>Je suis :</Typography>

                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <FactoryIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('userType', 'Entreprise/Industrie')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Entreprise/Industrie</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <DescriptionIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('userType', 'Investisseur')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Investisseur</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <EngineeringIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('userType', 'Promoteur')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Promoteur</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <HomeIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('userType', 'Collectivité')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Collectivité</Typography>
                            </Box>
                        </Box>
                    </>
                )}

                {step === 2 && (
                    <>

                        <Box sx={{ backgroundColor: 'rgba(62,139,190,0.6)', padding: '20px', width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center', gap: '30px', flexWrap: 'wrap' }}>
                            <ArrowBackIosIcon
                                sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', marginTop: '20px' }}
                                onClick={() => handleBack('projectType')}

                            />
                            <Typography variant='h4' sx={{ color: 'white', textAlign: 'center', width: '100%', fontFamily: 'Lato, sans-serif' }}>J'ai un projet de :</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ZoomInIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Recherche foncière')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Recherche foncière</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ArchitectureIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Conception et ingénierie')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Conception et ingénierie</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <HardwareIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Construction')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Construction</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <OpenInFullIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Extension')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Extension</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <AutoFixHighIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Rénovation')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Rénovation</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ExtensionIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('projectType', 'Aménagement/Fit out')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Aménagement/Fit out</Typography>
                            </Box>
                        </Box>
                    </>
                )}

                {step === 3 && (
                    <>


                        <Box sx={{ width: '100%', backgroundColor: 'rgba(62,139,190,0.6)', padding: '20px', display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center', gap: '30px', flexWrap: 'wrap' }}>
                            <ArrowBackIosIcon
                                sx={{ marginTop: '20px', color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', }}
                                onClick={() => handleBack('buildingType')}

                            />
                            <Typography variant='h4' sx={{ color: 'white', textAlign: 'center', width: '100%', fontFamily: 'Lato, sans-serif' }}>Type de bâtiment :</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <FactoryIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Usines/Unités de production')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Usines/Unités de productione</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <HomeWorkIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Bâtiment d’activités')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Bâtiment d’activités</Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <ScienceIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Centre R&D/Laboratoires')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Centre R&D/Laboratoires</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <BusinessIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Bureaux / Siège')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Bureaux/Siège</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <LocalShippingIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Plateforme logistique')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Plateforme logistique</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <MovieIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Infrastructures services/loisirs')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Infrastructures services/loisirs</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <StoreIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingType', 'Surfaces commerciales')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Surfaces commerciales</Typography>
                            </Box>
                        </Box>
                    </>
                )}

                {step === 4 && (
                    <>

                        <Box sx={{ backgroundColor: 'rgba(62,139,190,0.6)', padding: '20px', width: '100%', display: 'flex', flexDirection: 'row', marginTop: '20px', justifyContent: 'center', gap: '30px', flexWrap: 'wrap' }}>
                            <ArrowBackIosIcon
                                sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, marginTop: '20px', transition: 'all 0.3s ease-in-out', }}
                                onClick={() => handleBack('buildingSize')}

                            />
                            <Typography variant='h4' sx={{ padding: '20px', color: 'white', textAlign: 'center', width: '100%', fontFamily: 'Lato, sans-serif' }}>Taille du bâtiment :</Typography>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Crop169Icon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingSize', '2000 à 15 000 m²')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>2000 à 15 000 m²</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <Crop32Icon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingSize', '15 000 à 60 000 m²')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>15 000 à 60 000 m²</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <CropDinIcon sx={{ color: 'white', cursor: 'pointer', '&:hover': { transform: 'scale(1.1)' }, transition: 'all 0.3s ease-in-out', width: { xs: '60px', sm: '90px' }, height: { xs: '60px', sm: '90px' } }} onClick={() => handleSelection('buildingSize', 'Plus de 60 000 m²')} />
                                <Typography variant='h6' sx={{ color: 'white', fontFamily: 'Lato, sans-serif', fontWeight: '400' }}>Plus de 60 000 m²</Typography>
                            </Box>

                        </Box>
                    </>
                )}

                {step === 5 && (
                    <form onSubmit={handleSubmit}
                    >

                        <Grid container sx={{ width: '80%', margin: 'auto' }} spacing={3}>

                            <Grid item xs={12} sm={6}>
                                <TextField label="Nom" name="nom" required fullWidth value={formData.nom} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Nom' field='nom' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Prénom" required name="prenom" fullWidth value={formData.prenom} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Prénom' field='prenom' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Email" required name="email" type="email" fullWidth value={formData.email} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Email' field='email' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Téléphone" required name="telephone" fullWidth value={formData.telephone} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Téléphone' field='telephone' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Ville du projet" name="villeDuProjet" fullWidth value={formData.villeDuProjet} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Ville du projet' field='villeDuProjet' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField label="Société" required name="societe" fullWidth value={formData.societe} onChange={handleChange} variant="outlined" />
                                <ValidationError prefix='Société' field='societe' errors={state.errors} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Description du projet" required name="description" fullWidth value={formData.description} onChange={handleChange} multiline rows={4} variant="outlined" />
                                <ValidationError prefix='Description du projet' field='description' errors={state.errors} />
                            </Grid>

                            <input type="hidden" name="userType" value={formData.userType} />
                            <input type="hidden" name="projectType" value={formData.projectType} />
                            <input type="hidden" name="buildingType" value={formData.buildingType} />
                            <input type="hidden" name="buildingSize" value={formData.buildingSize} />

                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            required
                                            color="primary"
                                            name="consent"
                                            checked={formData.consent}
                                            onChange={handleChange}
                                        />
                                    }
                                    label="Je donne mon consentement."
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Dans le cadre du Règlement Général sur la Protection des Données (RGPD), merci de bien vouloir donner votre consentement sur l’utilisation de vos données.
                                    Vos données seront conservées 14 mois et sont destinées aux services Commerce et Marketing.
                                </Typography>
                                <Typography variant="body2" color="textSecondary" gutterBottom>
                                    Pour connaître et appliquer vos droits, notamment de retrait, modification et accès, veuillez consulter notre politique de confidentialité.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} style={{ textAlign: 'center' }}>
                                <Button type="submit" variant="contained" color="primary">
                                    Envoyer
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Box>
        </>
    )
}

export default ContactForm;